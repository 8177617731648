<template>
  <v-container>
    <UploadProfileAvatarDialog ref="upload" />
    <EditUserFullNameDialog ref="editUserFullName" />
    <changePasswordDialog ref="changePassword" />
    <ConfirmDeleteDialog ref="confirm" />
    <v-card>
      <v-card-text>
        <v-row justify="center">
          <v-avatar color="grey lighten-2" class="mx-2" size="120">
            <v-img :src="userAvatar"></v-img>
          </v-avatar>
        </v-row>
        <v-row justify="center">
          <h3>
            <p class="text-center">
              {{ userFullName }}
            </p>
          </h3>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="success"
              small
              block
              @click="openUploadProfileAvatarDialog()"
            >
              <v-icon left>
                mdi-camera
              </v-icon>
              อัพโหลดรูปโปรไฟล์
            </v-btn>
          </v-col>
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="error"
              small
              block
              @click="confirmDeleteProfileAvatar()"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              ลบรูปโปรไฟล์
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="success"
              small
              block
              @click="openEditFullNameDialog()"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              แก้ไขชื่อ
            </v-btn>
          </v-col>
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="error"
              small
              block
              @click="openChangePasswordDialog()"
            >
              <v-icon left>
                mdi-lock-reset
              </v-icon>
              เปลี่ยน Password
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="#1877F2"
              small
              block
              v-if="!this.facebookLinked"
              @click="linkFacebook"
            >
              <v-icon left>
                mdi-facebook
              </v-icon>
              เชื่อมต่อกับบัญชี facebook
            </v-btn>
            <v-btn
              outlined
              color="#1877F2"
              small
              block
              v-else
              @click="unlinkFacebook"
            >
              <v-icon left>
                mdi-facebook
              </v-icon>
              เลิกเชื่อมต่อกับบัญชี facebook
            </v-btn>
          </v-col>
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="#db3236"
              small
              block
              v-if="!this.googleLinked"
              @click="linkGoogle"
            >
              <v-icon left>
                mdi-google
              </v-icon>
              เชื่อมต่อกับบัญชี google
            </v-btn>
            <v-btn
              outlined
              color="#db3236"
              small
              block
              v-else
              @click="unlinkGoogle"
            >
              <v-icon left>
                mdi-google
              </v-icon>
              เลิกเชื่อมต่อกับบัญชี google
            </v-btn>
          </v-col>
          <v-col cols="12" xs="8" sm="6" md="4" lg="4" xl="2">
            <v-btn
              outlined
              color="#00c300"
              small
              block
              v-if="!this.lineLinked"
              @click="linkLine"
            >
              <v-icon left>
                fab fa-line
              </v-icon>
              เชื่อมต่อกับบัญชี line
            </v-btn>
            <v-btn
              outlined
              color="#00c300"
              small
              block
              v-else
              @click="unlinkLine"
            >
              <v-icon left>
                fab fa-line
              </v-icon>
              เลิกเชื่อมต่อกับบัญชี line
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import UploadProfileAvatarDialog from "./UploadProfileAvatarDialog";
import EditUserFullNameDialog from "./EditUserFullNameDialog";
import ChangePasswordDialog from "./changePasswordDialog";
import ConfirmDeleteDialog from "../../Shared/ConfirmDeleteDialog";

export default {
  name: "Profile",
  components: {
    UploadProfileAvatarDialog,
    ConfirmDeleteDialog,
    EditUserFullNameDialog,
    ChangePasswordDialog
  },
  props: {
    // msg: String,
  },
  data() {
    return {
      loading: false,
      user: null,
      facebookLinked: false,
      googleLinked: false,
      lineLinked: false,
      unlinkFacebookResponse: null,
      unlinkGoogleResponse: null,
      unlinkLineResponse: null,
      avatarPrefix:
        process.env.VUE_APP_STATIC_BASE_URL + "images/profile/avatars/",
    };
  },
  created() {
    this.getDataFromAPI();
  },
  computed: {
    userFullName() {
      return this.$store.getters['user/userFullName'];
    },
    userAvatar() {
      return this.avatarPrefix + this.$store.getters['user/userAvatar'];
    },
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/user/profile")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.user = response.data;
          this.facebookLinked = false;
          this.googleLinked = false;
          this.lineLinked = false;
          this.user.userAuthenticationProviders.map(
            (userAuthenticationProvider) => {
              if (userAuthenticationProvider.provider_type == "facebook") {
                this.facebookLinked = true;
              }
              if (userAuthenticationProvider.provider_type == "google") {
                this.googleLinked = true;
              }
              if (userAuthenticationProvider.provider_type == "line") {
                this.lineLinked = true;
              }
            }
          );
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    openUploadProfileAvatarDialog: async function() {
      await this.$refs.upload.open();
    },
    openEditFullNameDialog: async function() {
      await this.$refs.editUserFullName.open();
    },
    openChangePasswordDialog: async function() {
      await this.$refs.changePassword.open();
    },
    linkFacebook() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "user/authenticate/facebook/link/start";
    },

    linkGoogle() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "user/authenticate/google/link/start";
    },
    linkLine() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "user/authenticate/line/link/start";
    },
    unlinkFacebook() {
      this.loading = true;
      axios
        .get("/user/authenticate/facebook/unlink")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.unlinkFacebookResponse = response.data;
          this.loading = false;
          if (this.unlinkFacebookResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    unlinkGoogle() {
      this.loading = true;
      axios
        .get("/user/authenticate/google/unlink")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.unlinkGoogleResponse = response.data;
          this.loading = false;
          if (this.unlinkGoogleResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    unlinkLine() {
      this.loading = true;
      axios
        .get("/user/authenticate/line/unlink")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.unlinkLineResponse = response.data;
          this.loading = false;
          if (this.unlinkLineResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    confirmDeleteProfileAvatar: async function() {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบรูปโปรไฟล์",
          "คุณแน่ใจที่จะลบรูปโปรไฟล์ของคุณ?"
        )
      ) {
        this.deleteProfileAvatar();
      }
    },
    deleteProfileAvatar: async function() {
      this.loading = true;
      axios
        .get("/user/profile/avatar/delete/")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteProfileAvatarResponse = response.data;
          this.loading = false;
          if (this.deleteProfileAvatarResponse.status === "success") {
            this.$store
              .dispatch("user/changeAvatar", this.deleteProfileAvatarResponse.data.avatar)
              .then(() => {});
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
  },
};
</script>
